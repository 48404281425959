import React from 'react'
import { FaHeart } from 'react-icons/fa'

const Footer = props => (
  <footer id="footer">
    <section>
      <h2>About</h2>
      <p>
        Hackathons UK aims to support the UK hackathon scene by helping
        organisers share knowledge, help hackers learn and promote student
        events across the country.
      </p>
      <p>
        Supported by the organisers behind AstonHack, CovHack, DurHack,
        HackBrunel, HackNotts, hackSheffield, HackTheMidlands and ManMetHacks
        and <a href="https://hackathons.org.uk/events">many others</a>.
      </p>
    </section>
    <section>
      <h2>Contact</h2>
      <dl className="alt">
        <dt>Email</dt>
        <dd>
          <a href="mailto:hello@hackathons.org.uk">hello@hackathons.org.uk</a>
        </dd>
      </dl>
  
      <ul className="icons">
        <li>
          <a
            href="https://twitter.com/Hackathons_UK"
            className="icon fa-twitter alt"
          >
            <span className="label">Twitter</span>
          </a>
        </li>
        <li>
          <a
            href="https://github.com/hackathons-UK/"
            className="icon fa-github alt"
          >
            <span className="label">GitHub</span>
          </a>
        </li>
        <li>
          <a
            href="https://hack.athon.uk/discord"
            className="icon alt fa-discord"
          >
            <span className="label alt">Discord</span>
          </a>
        </li>
      </ul>
    </section>
    <p className="copyright">
      Made with <FaHeart /> by <a href="https://hackathons.org.uk">Hackathons UK</a> with a template by <a href="https://html5up.net">HTML5 UP</a>.
    </p>
  </footer>
)

export default Footer
